function Floor() {
  return (
    <div style={{
      width:"150vw",
      height:"10vh",
      position:"fixed",
      top:"90vh",
      left:"0",
      backgroundColor:"var(--floor)"}}>
    </div>
  );
}

export default Floor;